<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Match the glassware setups to the separation process:</p>

      <p class="mb-2">
        Filtration:
        <v-select
          v-model="inputs.input1"
          style="max-width: 250px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Thin Layer Chromatography:
        <v-select
          v-model="inputs.input2"
          style="max-width: 250px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-3">
        Crystallization:
        <v-select
          v-model="inputs.input3"
          style="max-width: 250px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <table>
        <tr>
          <th><stemble-latex content="$\text{I}$" /></th>
          <th><stemble-latex content="$\text{II}$" /></th>
          <th><stemble-latex content="$\text{III}$" /></th>
        </tr>
        <tr>
          <td>
            <v-img style="max-width: 190px" src="/img/assignments/UCIrvine/1LDQ4S2Q2img1.png" />
          </td>
          <td>
            <v-img style="max-width: 190px" src="/img/assignments/UCIrvine/1LDQ4S2Q2img2.png" />
          </td>
          <td>
            <v-img style="max-width: 190px" src="/img/assignments/UCIrvine/1LDQ4S2Q2img3.png" />
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ4S2Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      items: [
        {text: '$\\text{I}$', value: 'one'},
        {text: '$\\text{II}$', value: 'two'},
        {text: '$\\text{III}$', value: 'three'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>

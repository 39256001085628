var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v("Match the glassware setups to the separation process:")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Filtration: "),_c('v-select',{staticStyle:{"max-width":"250px","display":"inline-block"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Thin Layer Chromatography: "),_c('v-select',{staticStyle:{"max-width":"250px","display":"inline-block"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" Crystallization: "),_c('v-select',{staticStyle:{"max-width":"250px","display":"inline-block"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('table',[_c('tr',[_c('th',[_c('stemble-latex',{attrs:{"content":"$\\text{I}$"}})],1),_c('th',[_c('stemble-latex',{attrs:{"content":"$\\text{II}$"}})],1),_c('th',[_c('stemble-latex',{attrs:{"content":"$\\text{III}$"}})],1)]),_c('tr',[_c('td',[_c('v-img',{staticStyle:{"max-width":"190px"},attrs:{"src":"/img/assignments/UCIrvine/1LDQ4S2Q2img1.png"}})],1),_c('td',[_c('v-img',{staticStyle:{"max-width":"190px"},attrs:{"src":"/img/assignments/UCIrvine/1LDQ4S2Q2img2.png"}})],1),_c('td',[_c('v-img',{staticStyle:{"max-width":"190px"},attrs:{"src":"/img/assignments/UCIrvine/1LDQ4S2Q2img3.png"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }